import qs from 'qs'
import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  abortController = new AbortController()
  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        config.signal = this.abortController.signal
        // Get token from localStorage
        const accessToken = this.getToken()
        config.headers.Authorization =
          'Basic bXljbGllbnRpZDpteWNsaWVudHNlY3JldA=='
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

        if (accessToken) {
          config.headers['Content-Type'] = 'application/json'
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        if (error.name === 'AbortError') {
          console.error('Requisição cancelada')
        }

        // if (status === 401) {
        if (response && response.status === 401) {
          router.push({ name: 'login' })
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.logout()
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }

        if (response && response.status === 404) {
          return response.data
        }

        if (response && response.status === 403) {
          response.data.message = 'Usuário sem permissão para acessar o recurso'
          return response.data
        }

        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    const data = qs.stringify(...args)
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, data)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  getUsersById(id) {
    return this.axiosIns.get(this.jwtConfig.userByIdEndpoint + id)
  }

  logout() {
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('userData')
    localStorage.setItem('isLogged', false)
  }

  forgotPassword(email) {
    const axios = require('axios')
    let data = { email: email }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://teste.sysfactech.com.br:8081/public/esqueceu_senha',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ''
      },
      data: data
    }

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
